import { Container, CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { theme } from "assets/theme";
import Capture from "containers/Capture";
import Features from "containers/Features";
import Details from "containers/Interface/Details";
import TableStats from "containers/Interface/TableStats";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

function App() {
  const appTheme = createTheme(theme);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  useEffect(() => {
    setInnerHeight(window.innerHeight);
  }, [window.innerHeight]);

  return (
    <ThemeProvider theme={appTheme}>
      <Container
        fixed
        sx={{
          padding: "0",
          height: { innerHeight },
          background: "background.default",
        }}
        style={{ height: innerHeight }}
      >
        <CssBaseline />
        <Routes>
          <Route path="/" exact element={<Features />} />
          <Route path="/capture/feature/:id/:code" exact element={<Capture />} />
          <Route path="/details" exact element={<Details />} />
          <Route path="/stats" exact element={<TableStats />} />
        </Routes>
      </Container>
    </ThemeProvider>
  );
}

export default App;
