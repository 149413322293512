import { useRef, useState, useEffect } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";

import Cam from "components/Camera";
import { Image, PanoramaFishEye, CameraswitchRounded } from "@mui/icons-material";
import detectPlatform from "services/utils/detectPlatform";
import NavigateToBack from "components/NavigateToback/NavigateToBack";
import Preview from "components/Preview/Preview";
import { useNavigate, useParams } from "react-router-dom";

import "style/frame.css";
import generateBoundingBoxes from "services/utils/generateBoundingBox";
import { getIdentity } from "config/apiHandler";
import uploadImageAndGetURL from "services/exports/uploadImageAndGetURL";
import convertToYOLOv8Labels from "services/utils/convertToYOLOv8Labels";
import uploadFileToS3 from "services/exports/uploadFileToS3";
import uniqueId from "services/utils/generateUniqueId";
import AWS from "aws-sdk";
import CircularProgress from "@mui/material/CircularProgress";
import decrypt from "services/utils/decrypt";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

// const projectPath =
//   process.env.REACT_APP_PROJECT_NAME === "tdc-iod"
//     ? "tdc-iod/mock"
//     : process.env.REACT_APP_PROJECT_NAME;

function LiveShot({ coors, image, partName, test_url }) {
  const param = useParams();
  const [newCoors, setNewCoors] = useState(coors);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const changeBoxPosition = () => {
    const vid = document.getElementById("video");
    const video = {
      width: vid.clientWidth,
      height: vid.clientHeight,
    };

    // const tempBox = generateBoundingBoxes(coors, image);
    const tempBox = generateBoundingBoxes(coors, video);
    tempBox.x -= tempBox.width / 2;
    tempBox.y -= tempBox.height / 2;
    setNewCoors(tempBox);
  };

  useEffect(() => {
    if (coors) {
      changeBoxPosition();
    }
  }, []);

  const Navigate = useNavigate();
  const [facingMode, setFacingMode] = useState(
    detectPlatform() === "web" ? FACING_MODE_USER : FACING_MODE_ENVIRONMENT
  );
  const [imgSrc, setImgSrc] = useState(null);
  const [fileName, setFileName] = useState("unknown.jpeg");
  const webcamRef = useRef(null);
  const fileUploadRef = useRef(null);
  const [FileCount, setFileCount] = useState([]);

  const fetchImageCount = async () => {
    const decryptedAccessKeyId = await decrypt(process.env.REACT_APP_ENCRYPTED_AWS_ACCESS_KEY_ID);
    const decryptedSecretAccessKey = await decrypt(
      process.env.REACT_APP_ENCRYPTED_AWS_SECRET_ACCESS_KEY
    );

    const s3 = new AWS.S3({
      accessKeyId: decryptedAccessKeyId,
      secretAccessKey: decryptedSecretAccessKey,
      region: "ap-south-1",
    });
    const identifier = await getIdentity();

    const params = {
      Bucket: "xane-data",
      Prefix: `projects/${process.env.REACT_APP_PROJECT_NAME}/data/${identifier}/${param.id}/`,
      MaxKeys: 1000, // Set initial MaxKeys to 1000
    };

    let totalCount = 0;

    function listAllObjects() {
      s3.listObjects(params, (err, data) => {
        if (err) {
          console.error(err);
        } else {
          const objects = data.Contents;
          const jpgFiles = objects.filter((obj) => obj.Key.endsWith(".jpeg"));
          const jpgFileCount = jpgFiles.length;
          totalCount += jpgFileCount;
          if (data.IsTruncated) {
            // If there are more objects, retrieve the next batch
            params.Marker = objects[objects.length - 1].Key;
            listAllObjects(); // Recursively call listAllObjects with updated params
          } else {
            // If no more objects, set the total count
            setFileCount(totalCount);
          }
        }
      });
    }

    listAllObjects();
  };
  useEffect(() => {
    fetchImageCount();
  }, []);
  const handleSwitch = () => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER
    );
  };

  const capture = async () => {
    setIsLoading(true);
    const identifier = await getIdentity();
    const imageSrc = await webcamRef.current.takePhoto();
    setImgSrc(imageSrc);

    const uuid = uniqueId();

    const uploadPromise = uploadImageAndGetURL(
      imageSrc,
      `projects/${process.env.REACT_APP_PROJECT_NAME}/data/${identifier}/${param.id}/${uuid}.jpeg`
    );

    let timeoutId;

    const timeoutPromise = new Promise((_, reject) => {
      timeoutId = setTimeout(() => {
        reject(new Error("Slow internet. Please retry."));
      }, 10000);
    });

    const vid = document.getElementById("video");
    const scaleX = image.width / vid.clientWidth;
    const scaleY = image.height / vid.clientHeight;
    const tempCoorse = newCoors;
    tempCoorse.x *= scaleX;
    tempCoorse.y *= scaleY;
    tempCoorse.width *= scaleX;
    tempCoorse.height *= scaleY;
    setNewCoors(tempCoorse);
    const res = await convertToYOLOv8Labels({ ...tempCoorse, label_id: param.id }, image);
    const metaData = {
      Body: res,
      Key: `projects/${process.env.REACT_APP_PROJECT_NAME}/data/${identifier}/${param.id}/${uuid}.txt`,
      // ContentType: fileType === "image/jpeg" ? "image/jpeg" : "image/png",
    };

    const fileUrl = await uploadFileToS3(metaData);
    if (fileUrl) {
      setFileCount((prevProp) => prevProp + 1);
      changeBoxPosition();
      // setIsLoading(false);
    }

    try {
      const live_url = await Promise.race([uploadPromise, timeoutPromise]);
      await fetch(process.env.REACT_APP_TDC_SCORING_API, {
        method: "POST",
        body: JSON.stringify({
          base_image_url: test_url,
          input_image_url: live_url,
          project: process.env.REACT_APP_PROJECT_NAME,
          created_at: new Date().toISOString(),
        }),
        headers: { "Content-Type": "application/json" },
      });
    } catch (error) {
      alert(error.message);
    } finally {
      setIsLoading(false);
      clearTimeout(timeoutId);
    }

    // handleCoors(crop, image);
    // fetchImageCount();
  };

  const handleCloseImagePreview = () => {
    setImgSrc(null);
  };

  const renderPreview = async (event) => {
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    const result = await toBase64(event.target.files[0]);
    setFileName(event.target.files[0].name);
    setImgSrc(result);
  };

  const handleProceed = (crop) => {
    const requestBody = { buffer: imgSrc, fileName };
    // handlePostFile(requestBody);
    // handleCoors(crop);
    // Navigate("/app/result");
  };
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <NavigateToBack />
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            color: "#D3D3D3",
            textShadow: `rgb(211, 211, 211,0.4) 3px 3px 3px `,
          }}
        >
          {partName.replace(/-/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            color: "#D3D3D3",
            textShadow: `rgb(211, 211, 211,0.4) 3px 3px 3px `,
            marginRight: "15px",
          }}
        >
          {FileCount}
        </Typography>
      </Stack>
      {/* {imgSrc && (
        <Preview
          imageSrc={imgSrc}
          handleCloseImagePreview={handleCloseImagePreview}
          handleProceed={handleProceed}
        />
      )} */}
      <Box sx={{ padding: "2%" }}>
        <div className="overlay-video-container">
          <div
            className="bounding-box"
            style={{
              top: `${newCoors.y}px`,
              left: `${newCoors.x}px`,
              width: `${newCoors.width}px`,
              height: `${newCoors.height}px`,
            }}
          />
          <Cam webcamRef={webcamRef} facingMode={facingMode} />
        </div>
      </Box>
      {/* 
      <Stack direction="row" sx={{ justifyContent: "space-evenly" }}>
        <IconButton
          aria-label="file"
          sx={{ color: "#D3D3D3" }}
          size="large"
          onClick={() => fileUploadRef.current?.click()}
        >
          <input
            ref={fileUploadRef}
            onChange={renderPreview}
            type="file"
            id="avatar"
            name="avatar"
            accept="image/png"
            style={{ display: "none" }}
          />
          <Image fontSize="inherit" />
        </IconButton>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <IconButton aria-label="capture" sx={{ color: "#D3D3D3" }} size="large" onClick={capture}>
            <PanoramaFishEye fontSize="inherit" sx={{ fontSize: "310%" }} />
          </IconButton>
        )}
        <IconButton
          sx={{ color: "#D3D3D3" }}
          aria-label="rotate"
          size="large"
          onClick={handleSwitch}
        >
          <CameraswitchRounded fontSize="inherit" />
        </IconButton>
      </Stack>
      */}
      <Stack direction="row" sx={{ justifyContent: "center" }}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <IconButton aria-label="capture" sx={{ color: "#D3D3D3" }} size="large" onClick={capture}>
            <PanoramaFishEye fontSize="inherit" sx={{ fontSize: "310%" }} />
          </IconButton>
        )}
      </Stack>
    </Box>
  );
}
export default LiveShot;
LiveShot.propTypes = {
  // saveCurrentFileBuffer: PropTypes.func.isRequired,
  // handlePostFile: PropTypes.func.isRequired,
};
