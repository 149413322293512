import React, { useEffect, useRef } from "react";

// CanvasOverlay Component to Draw Bounding Box
function CanvasOverlay({ imageUrl, coordinates }) {
  const canvasRef = useRef(null);
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);

      // Parse coordinates from the new JSON structure
      const [x_center, y_center, width, height] = JSON.parse(coordinates);

      // Convert coordinates to pixel values
      const box_x = (x_center - width / 2) * img.width;
      const box_y = (y_center - height / 2) * img.height;
      const box_width = width * img.width;
      const box_height = height * img.height;

      // Draw the bounding box
      ctx.strokeStyle = "red";
      ctx.lineWidth = 5;
      ctx.strokeRect(box_x, box_y, box_width, box_height);
    };
  }, [imageUrl, coordinates]);

  return <canvas ref={canvasRef} style={{ width: "100%", height: "100%" }} />;
}
export default CanvasOverlay;
