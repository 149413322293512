/* eslint-disable no-nested-ternary */
// /* eslint-disable no-nested-ternary */

import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Card,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CanvasOverlay from "views/Interface/components/CanvasOverlay";
import { useNavigate } from "react-router-dom";

function Details() {
  const [selectedFeature, setSelectedFeature] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedIdentifier, setSelectedIdentifier] = useState("");
  const [stats, setStats] = useState({});
  const [filteredImages, setFilteredImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [apiData, setApiData] = useState([]);
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://k02d9ev0xg.execute-api.ap-south-1.amazonaws.com/development/details"
        );
        const data = await response.json();
        setApiData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Extract unique features from API data
  const features = apiData
    ? [...new Set(apiData.map((image) => image.label))].map((name) => {
        const imageWithLabel = apiData.find((image) => image.label === name);
        return {
          id: imageWithLabel.id,
          name,
        };
      })
    : [];

  // Add new selector for unique identifiers
  const identifierOptions = apiData
    ? [...new Set(apiData.map((image) => image.identifier))].map((identifier) => ({
        value: identifier,
        label: identifier,
      }))
    : [];

  // Status options for the Select dropdown
  const statusOptions = [
    { value: "all", label: "All" },
    { value: "accepted", label: "Accepted" },
    { value: "rejected", label: "Rejected" },
  ];

  // Extract unique dates from API data and format them to "dd/mm/yyyy"
  const dateOptions = apiData
    ? [
        ...new Set(apiData.map((image) => new Date(image.created_at).toISOString().split("T")[0])),
      ].map((date) => {
        const [year, month, day] = date.split("-");
        const formattedDate = `${day}/${month}/${year}`;
        const imageWithDate = apiData.find(
          (image) => new Date(image.created_at).toISOString().split("T")[0] === date
        );
        return {
          id: imageWithDate.id,
          date: formattedDate,
          originalDate: date,
        };
      })
    : [];

  // Update the filtering logic to include identifier
  useEffect(() => {
    if (selectedFeature && apiData) {
      const selectedFeatureData = apiData.filter((image) => image.label === selectedFeature);
      if (selectedFeatureData.length > 0) {
        const filtered = selectedFeatureData.filter((image) => {
          const matchesStatus = selectedStatus === "all" || image.status === selectedStatus;
          const matchesDate =
            !selectedDate ||
            new Date(image.created_at).toISOString().split("T")[0] === selectedDate;
          const matchesIdentifier = !selectedIdentifier || image.identifier === selectedIdentifier;
          return matchesStatus && matchesDate && matchesIdentifier;
        });

        const total_images = filtered.length;
        const accepted = filtered.filter((image) => image.status === "accepted").length;
        const rejected = filtered.filter((image) => image.status === "rejected").length;
        const acceptance_rate = ((accepted / total_images) * 100).toFixed(2);
        const source = filtered.length > 0 ? filtered[0].identifier : "N/A";
        setStats({
          total: total_images,
          accepted,
          rejected,
          rate: acceptance_rate,
          source,
        });

        setFilteredImages(filtered);
      }
    } else {
      setStats({});
      setFilteredImages([]);
    }
  }, [selectedFeature, selectedStatus, selectedDate, selectedIdentifier, apiData]);

  // Handle feature change
  const handleFeatureChange = (event) => {
    setSelectedFeature(event.target.value);
    setSelectedStatus("all");
    setSelectedDate("");
    setSelectedIdentifier("");
  };
  // Handle status change
  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    setCurrentPage(1);
  };

  // Handle date change
  const handleDateChange = (event) => {
    if (event.target.value === "clear") {
      setSelectedDate("");
    } else {
      const selectedDateOption = dateOptions.find((option) => option.date === event.target.value);
      setSelectedDate(selectedDateOption ? selectedDateOption.originalDate : "");
    }
    setCurrentPage(1);
  };

  // Handle identifier change
  const handleIdentifierChange = (event) => {
    setSelectedIdentifier(event.target.value);
    setCurrentPage(1);
  };

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Handle image click
  const handleImageClick = (imageUrl, index) => {
    setSelectedImage(imageUrl);
    setSelectedImageIndex(index);
    setOpenDialog(true);
  };

  // Handle dialog close
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Get grid columns based on screen size
  const getGridColumns = () => {
    if (isMobile) return "repeat(1, 1fr)";
    if (isTablet) return "repeat(2, 1fr)";
    return "repeat(4, 1fr)";
  };

  const handleBack = () => {
    navigate(-1);
  };

  // Pagination logic
  const itemsPerPage = 12;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentImages = filteredImages.slice(startIndex, startIndex + itemsPerPage);
  const totalPages = Math.ceil(filteredImages.length / itemsPerPage);

  return (
    <Box sx={{ padding: isMobile ? 6 : 8, color: "#ffffff", marginTop: "50px" }}>
      <IconButton
        onClick={handleBack}
        sx={{
          color: theme.palette.common.white,
          alignSelf: "flex-start",
        }}
      >
        <ArrowBackIcon />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: { xs: "column", sm: "row", md: "row" },
          gap: "20px",
          padding: "10px",
        }}
      >
        {/* Feature Select Dropdown */}
        <Select
          value={selectedFeature}
          onChange={handleFeatureChange}
          displayEmpty
          fullWidth
          sx={{
            marginBottom: 4,
            backgroundColor: "rgba(255, 255, 255, 0.95)",
            color: "#162936",
            borderRadius: "12px",
            boxShadow: "0 8px 32px rgba(0,0,0,0.15)",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#3498db",
            },
          }}
        >
          <MenuItem value="" disabled>
            Select a Feature
          </MenuItem>
          {features.map((feature) => (
            <MenuItem key={feature.id} value={feature.name}>
              {feature.name}
            </MenuItem>
          ))}
        </Select>

        {/* Identifier Select Dropdown */}
        {selectedFeature && (
          <Select
            value={selectedIdentifier}
            onChange={handleIdentifierChange}
            displayEmpty
            fullWidth
            sx={{
              marginBottom: 4,
              backgroundColor: "rgba(255, 255, 255, 0.95)",
              color: "#162936",
              borderRadius: "12px",
              boxShadow: "0 8px 32px rgba(0,0,0,0.15)",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#3498db",
              },
            }}
          >
            <MenuItem value="" disabled>
              Select an Identifier
            </MenuItem>
            <MenuItem value="">All Identifiers</MenuItem>
            {identifierOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}

        {/* Date Select Dropdown */}
        {selectedFeature && (
          <Select
            value={
              selectedDate
                ? dateOptions.find((option) => option.originalDate === selectedDate)?.date
                : ""
            }
            onChange={handleDateChange}
            displayEmpty
            fullWidth
            sx={{
              marginBottom: 4,
              backgroundColor: "rgba(255, 255, 255, 0.95)",
              color: "#162936",
              borderRadius: "12px",
              boxShadow: "0 8px 32px rgba(0,0,0,0.15)",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#3498db",
              },
            }}
          >
            <MenuItem value="" disabled>
              Select a Date
            </MenuItem>
            <MenuItem value="clear">Clear Date</MenuItem>
            {dateOptions.map((date) => (
              <MenuItem key={date.id} value={date.date}>
                {date.date}
              </MenuItem>
            ))}
          </Select>
        )}

        {/* Status Select Dropdown */}
        {selectedFeature && (
          <Select
            value={selectedStatus}
            onChange={handleStatusChange}
            displayEmpty
            fullWidth
            sx={{
              marginBottom: 4,
              backgroundColor: "rgba(255, 255, 255, 0.95)",
              color: "#162936",
              borderRadius: "12px",
              boxShadow: "0 8px 32px rgba(0,0,0,0.15)",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#3498db",
              },
            }}
          >
            {statusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      </Box>

      {/* Stats and summary Box */}
      <Box
        sx={{ display: "flex", flexDirection: { xs: "column", sm: "row", md: "row" }, gap: "5px" }}
      >
        {selectedFeature && (
          <Box
            sx={{
              backgroundColor: "rgba(22, 41, 54, 0.85)",
              padding: 5,
              borderRadius: "24px",
              color: "#ffffff",
              width: "350px",
              margin: "50px auto",
              backdropFilter: "blur(20px)",
              boxShadow: "0 20px 40px rgba(0,0,0,0.3)",
              border: "1px solid rgba(255,255,255,0.1)",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                marginBottom: 4,
                textAlign: "center",
                fontWeight: 600,
                letterSpacing: "0.5px",
              }}
            >
              Stats
            </Typography>
            <Typography sx={{ textAlign: "center", fontSize: "1.1rem", marginBottom: 3 }}>
              Device Id: {stats.source}
            </Typography>

            {selectedStatus === "all" && (
              <>
                <Typography sx={{ textAlign: "center", fontSize: "1.1rem", marginBottom: 3 }}>
                  Total Images: {stats.total}
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "1.1rem",
                    marginBottom: 3,
                    color: "#2ecc71",
                  }}
                >
                  Acceptance Rate: {stats.rate ? `${stats.rate}%` : "null"}
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "1.1rem",
                    marginBottom: 3,
                    color: "#2ecc71",
                  }}
                >
                  Accepted: {stats.accepted}
                </Typography>
                <Typography sx={{ textAlign: "center", fontSize: "1.1rem", color: "#e74c3c" }}>
                  Rejected: {stats.rejected}
                </Typography>
              </>
            )}

            {selectedStatus === "accepted" && (
              <>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "1.1rem",
                    marginBottom: 3,
                    color: "#2ecc71",
                  }}
                >
                  Acceptance Rate: {stats.rate}%
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "1.1rem",
                    marginBottom: 3,
                    color: "#2ecc71",
                  }}
                >
                  Accepted: {stats.accepted}
                </Typography>
              </>
            )}

            {selectedStatus === "rejected" && (
              <Typography sx={{ textAlign: "center", fontSize: "1.1rem", color: "#e74c3c" }}>
                Rejected: {stats.rejected}
              </Typography>
            )}
          </Box>
        )}
      </Box>

      {/* Image Grid */}
      {selectedFeature && (
        <Box sx={{ marginTop: 20 }}>
          <Typography
            variant="h5"
            sx={{
              marginBottom: 4,
              textAlign: "center",
              fontWeight: 600,
              textShadow: "2px 2px 4px rgba(0,0,0,0.2)",
            }}
          >
            {selectedStatus === "all"
              ? "All Images"
              : selectedStatus === "accepted"
              ? "Accepted Images"
              : "Rejected Images"}
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: getGridColumns(),
              gap: 20,
            }}
          >
            {currentImages.map((image, index) => (
              <Card
                key={image.image_url}
                onClick={() => handleImageClick(image.image_url, index)}
                sx={{
                  height: "100%",
                  width: "100%",
                  border: "1px solid white",
                  borderRadius: 4,
                  boxShadow: "0 15px 35px rgba(0,0,0,0.2)",
                  transition: "0.3s",
                  "&:hover": {
                    transform: "translateY(-10px)",
                    boxShadow: "0 20px 40px rgba(0,0,0,0.3)",
                  },
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                <Typography
                  sx={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    backgroundColor: "rgba(0,0,0,0.7)",
                    color: "#2ecc71",
                    padding: "4px 8px",
                    borderRadius: "6px",
                    fontSize: "14px",
                  }}
                >
                  {startIndex + index + 1}
                </Typography>
                <CanvasOverlay imageUrl={image.image_url} coordinates={image.coordinates} />
              </Card>
            ))}
          </Box>
        </Box>
      )}

      {/* Pagination */}
      {selectedFeature && (
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          sx={{
            marginTop: 10,
            display: "flex",
            justifyContent: "center",
            "& .MuiPaginationItem-root": {
              color: "#fff",
            },
            "& .Mui-selected": {
              backgroundColor: "#fff",
              color: "#000",
            },
            "& .MuiPaginationItem-ellipses": {
              color: "#fff",
            },
          }}
        />
      )}

      {/* Dialog for Full-Size Image */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <IconButton color="inherit" onClick={handleCloseDialog}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            width: "100%",
            height: "inherit",
            position: "relative",
          }}
        >
          <Typography
            sx={{
              position: "absolute",
              top: 10,
              right: 40,
              backgroundColor: "rgba(0,0,0,0.7)",
              color: "#2ecc71",
              padding: "4px 8px",
              borderRadius: "6px",
              fontSize: "14px",
              zIndex: 1,
            }}
          >
            {startIndex + selectedImageIndex + 1}
          </Typography>
          <CanvasOverlay
            imageUrl={selectedImage}
            coordinates={currentImages.find((img) => img.image_url === selectedImage)?.coordinates}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Details;
