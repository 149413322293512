/* eslint-disable no-nested-ternary */
// import React from "react";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   useMediaQuery,
//   useTheme,
//   IconButton,
//   Box,
// } from "@mui/material";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import { useNavigate } from "react-router-dom";
// import { format } from "date-fns";
// import StatsData from "data/Stats.json";

// function TableStats() {
//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
//   const navigate = useNavigate();

//   const handleBack = () => {
//     navigate(-1);
//   };

//   // Function to format date
//   const formatDate = (dateString) => format(new Date(dateString), "dd MMM yyyy"); // Format date as "10 Mar 2025"
//   return (
//     <Box
//       sx={{
//         padding: "16px",
//         backgroundColor: theme.palette.background.default,
//         minHeight: "100vh",
//       }}
//     >
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           gap: "16px",
//           maxWidth: "1200px",
//           margin: "0 auto",
//         }}
//       >
//         <IconButton
//           onClick={handleBack}
//           sx={{
//             color: theme.palette.common.white,
//             alignSelf: "flex-start",
//           }}
//         >
//           <ArrowBackIcon />
//         </IconButton>

//         <TableContainer
//           component={Paper}
//           sx={{
//             boxShadow: 3,
//             overflowX: "auto",
//             maxHeight: "550px",
//             backgroundColor: theme.palette.background.paper,
//           }}
//         >
//           <Table
//             sx={{
//               minWidth: 650,
//               "& .MuiTableCell-root": {
//                 fontSize: isSmallScreen ? "12px" : "14px",
//                 padding: isSmallScreen ? "8px" : "16px",
//               },
//             }}
//             aria-label="stats table"
//           >
//             <TableHead>
//               <TableRow
//                 sx={{
//                   backgroundColor: theme.palette.primary.main,
//                   position: "sticky",
//                   top: 0,
//                   zIndex: 1,
//                 }}
//               >
//                 <TableCell sx={{ fontWeight: "bold", color: theme.palette.common.white }}>
//                   Label
//                 </TableCell>
//                 <TableCell sx={{ fontWeight: "bold", color: theme.palette.common.white }}>
//                   Accepted
//                 </TableCell>
//                 <TableCell sx={{ fontWeight: "bold", color: theme.palette.common.white }}>
//                   Rejected
//                 </TableCell>
//                 <TableCell sx={{ fontWeight: "bold", color: theme.palette.common.white }}>
//                   Identifier
//                 </TableCell>
//                 <TableCell sx={{ fontWeight: "bold", color: theme.palette.common.white }}>
//                   Date
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {StatsData.map((item) => (
//                 <TableRow
//                   key={item.identifier}
//                   sx={{
//                     "&:nth-of-type(odd)": { backgroundColor: theme.palette.action.hover },
//                     "&:hover": {
//                       backgroundColor: theme.palette.action.selected,
//                       cursor: "pointer",
//                     },
//                   }}
//                 >
//                   <TableCell>{item.label}</TableCell>
//                   <TableCell>{item.accepted_count}</TableCell>
//                   <TableCell>{item.rejected_count}</TableCell>
//                   <TableCell>{item.identifier}</TableCell>
//                   <TableCell>{formatDate(item.date)}</TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </Box>
//     </Box>
//   );
// }

// export default TableStats;
import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
  useTheme,
  IconButton,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

import dateFormatter from "services/utils/dateFormatter";

function TableStats() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const [statsData, setStatsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://k02d9ev0xg.execute-api.ap-south-1.amazonaws.com/development/stats"
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setStatsData(data);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box
      sx={{
        padding: "16px",
        backgroundColor: theme.palette.background.default,
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          maxWidth: "1200px",
          margin: "0 auto",
        }}
      >
        <IconButton
          onClick={handleBack}
          sx={{
            color: theme.palette.common.white,
            alignSelf: "flex-start",
          }}
        >
          <ArrowBackIcon />
        </IconButton>

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography variant="h6" color="error" sx={{ textAlign: "center", marginTop: "20px" }}>
            Error: {error}
          </Typography>
        ) : (
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: 3,
              overflowX: "auto",
              maxHeight: "550px",
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Table
              sx={{
                minWidth: 650,
                "& .MuiTableCell-root": {
                  fontSize: isSmallScreen ? "12px" : "14px",
                  padding: isSmallScreen ? "8px" : "16px",
                },
              }}
              aria-label="stats table"
            >
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  <TableCell sx={{ fontWeight: "bold", color: theme.palette.common.white }}>
                    Serial No:
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: theme.palette.common.white }}>
                    Label
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: theme.palette.common.white }}>
                    Accepted
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: theme.palette.common.white }}>
                    Rejected
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: theme.palette.common.white }}>
                    Identifier
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: theme.palette.common.white }}>
                    Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {statsData.map((item, index) => (
                  <TableRow
                    key={item.identifier}
                    sx={{
                      "&:nth-of-type(odd)": { backgroundColor: theme.palette.action.hover },
                      "&:hover": {
                        backgroundColor: theme.palette.action.selected,
                        cursor: "pointer",
                      },
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.label}</TableCell>
                    <TableCell>{item.accepted_count}</TableCell>
                    <TableCell>{item.rejected_count}</TableCell>
                    <TableCell>{item.identifier}</TableCell>
                    <TableCell>{dateFormatter(item.date)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Box>
  );
}

export default TableStats;
