import { Box } from "@mui/material";
import React from "react";
import DetailsView from "views/Interface/Details";

function Details() {
  return (
    <Box>
      <DetailsView />
    </Box>
  );
}
export default Details;
