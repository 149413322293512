import { Box } from "@mui/material";
import React from "react";
import TableStatsView from "views/Interface/TableStats";

function TableStats() {
  return (
    <Box>
      <TableStatsView />
    </Box>
  );
}
export default TableStats;
