import { useRef, useState, useEffect } from "react";
import { Box, CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";

import Cam from "components/Camera";
import { Image, PanoramaFishEye, CameraswitchRounded } from "@mui/icons-material";
import detectPlatform from "services/utils/detectPlatform";
import NavigateToBack from "components/NavigateToback/NavigateToBack";
import Preview from "components/Preview/Preview";
import { useNavigate, useParams } from "react-router-dom";
import uploadImageAndGetURL from "services/exports/uploadImageAndGetURL";
import uploadToS3 from "services/exports/uploadToS3";
import uploadFileToS3 from "services/exports/uploadFileToS3";
import { getIdentity } from "config/apiHandler";
import convertToYOLOv8Labels from "services/utils/convertToYOLOv8Labels";
import getImageDimensions from "services/utils/getImageDimensions";
import uniqueId from "services/utils/generateUniqueId";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

function TestShot({ handleCoors, partName }) {
  const param = useParams();
  const Navigate = useNavigate();
  const [facingMode, setFacingMode] = useState(
    detectPlatform() === "web" ? FACING_MODE_USER : FACING_MODE_ENVIRONMENT
  );
  const [imgSrc, setImgSrc] = useState(null);
  const [fileName, setFileName] = useState("unknown.jpeg");
  const webcamRef = useRef(null);
  const fileUploadRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSwitch = () => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER
    );
  };

  const capture = () => {
    const imageSrc = webcamRef.current.takePhoto();
    setImgSrc(imageSrc);
  };
  const handleCloseImagePreview = () => {
    setImgSrc(null);
  };

  const renderPreview = async (event) => {
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    const result = await toBase64(event.target.files[0]);
    setFileName(event.target.files[0].name);
    setImgSrc(result);
  };

  const handleProceed = async (crop) => {
    setIsLoading(true);
    const identifier = await getIdentity();
    const uuid = uniqueId();
    const test_url = await uploadImageAndGetURL(
      imgSrc,
      `projects/${process.env.REACT_APP_PROJECT_NAME}/data/${identifier}/test/${param.id}/${uuid}.jpeg`
    );

    // handlePostFile(requestBody);
    if (crop?.crop) {
      const image = { height: 0, width: 0 };
      await getImageDimensions(imgSrc)
        .then(({ width, height }) => {
          image.height = height;
          image.width = width;
        })
        .catch((error) => {
          console.error("Error:", error);
        });

      const vid = document.getElementById("video");
      const scaleX = image.width / vid.clientWidth;
      const scaleY = image.height / vid.clientHeight;

      crop.crop.x *= scaleX;
      crop.crop.y *= scaleY;
      crop.crop.width *= scaleX;
      crop.crop.height *= scaleY;

      const res = await convertToYOLOv8Labels({ ...crop.crop, label_id: param.id }, image);
      const metaData = {
        Body: res,
        Key: `projects/${process.env.REACT_APP_PROJECT_NAME}/data/${identifier}/test/${param.id}/${uuid}.txt`,
        // ContentType: fileType === "image/jpeg" ? "image/jpeg" : "image/png",
      };

      const fileUrl = await uploadFileToS3(metaData);

      crop.crop.x /= scaleX;
      crop.crop.y /= scaleY;
      crop.crop.width /= scaleX;
      crop.crop.height /= scaleY;
      setIsLoading(false);
      handleCoors(crop, image, test_url);
    }

    // Navigate("/app/result");
  };
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
        }}
      >
        <NavigateToBack />
        <Typography variant="h6" color="#D3D3D3">
          {partName.replace(/-/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())}
        </Typography>
      </Stack>
      {imgSrc && (
        <Preview
          imageSrc={imgSrc}
          handleCloseImagePreview={handleCloseImagePreview}
          handleProceed={handleProceed}
          partName={partName}
        />
      )}
      <Box sx={{ position: "relative", padding: "8px" }}>
        <Cam webcamRef={webcamRef} facingMode={facingMode} />
      </Box>
      {/* 
      <Stack direction="row" sx={{ justifyContent: "space-evenly" }}>
        <IconButton
          aria-label="file"
          sx={{ color: "#D3D3D3" }}
          size="large"
          onClick={() => fileUploadRef.current?.click()}
        >
          <input
            ref={fileUploadRef}
            onChange={renderPreview}
            type="file"
            id="avatar"
            name="avatar"
            accept="image/png"
            style={{ display: "none" }}
          />
          <Image fontSize="inherit" />
        </IconButton>

        <IconButton aria-label="capture" sx={{ color: "#D3D3D3" }} size="large" onClick={capture}>
          <PanoramaFishEye fontSize="inherit" sx={{ fontSize: "310%" }} />
        </IconButton>
        <IconButton
          sx={{ color: "#D3D3D3" }}
          aria-label="rotate"
          size="large"
          onClick={handleSwitch}
        >
          <CameraswitchRounded fontSize="inherit" />
        </IconButton>
      </Stack>
      */}
      <Stack direction="row" sx={{ justifyContent: "center" }}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <IconButton aria-label="capture" sx={{ color: "#D3D3D3" }} size="large" onClick={capture}>
            <PanoramaFishEye fontSize="inherit" sx={{ fontSize: "310%" }} />
          </IconButton>
        )}
      </Stack>
    </Box>
  );
}
export default TestShot;
TestShot.propTypes = {
  // saveCurrentFileBuffer: PropTypes.func.isRequired,
  // handlePostFile: PropTypes.func.isRequired,
};
